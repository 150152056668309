import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

const Skill = ({ name, level }) => {
  return (
    <SkillBox title={`${name} (${level}%)`}>
      <SkillName aria-hidden>{name}</SkillName>
      <Level className="level" style={{ width: `${level}%` }}></Level>
    </SkillBox>
  );
};

Skill.propTypes = {
  name: PropTypes.string.isRequired,
  level: PropTypes.number,
};

const BorderRadius = css`
  border-radius: 10px;
`;

const AbsoluteFullSpace = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const SkillBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0.75rem 1rem;
  overflow: hidden;
  ${BorderRadius}

  /* The folling rules are to fix a bug on Safari when border-radius is used along with overflow: hidden */
  mask-image: radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  &::before {
    content: "";
    ${AbsoluteFullSpace}
    opacity: ${props => props.theme.opacity.light};
    border: 1px solid ${props => props.theme.palette.white};
    box-sizing: border-box;
    ${BorderRadius}
    transition: ${props => props.theme.transition.default("opacity")};
  }

  &::after {
    content: "";
    ${AbsoluteFullSpace}
    ${BorderRadius}
      transition: ${props =>
      props.theme.transition.default("opacity")};
    box-sizing: border-box;
    background-color: ${props => props.theme.palette.white};
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: ${props => props.theme.opacity.faded};
    }

    & > .level {
      opacity: 1;
      transform: scaleY(3);
    }

    &::after {
      opacity: ${props => props.theme.opacity.bare};
    }
  }
`;

const SkillName = styled.span`
  font-size: ${props => props.theme.typography.sizes.regular}rem;
`;

const Level = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: ${props => props.theme.palette.white};
  height: 1px;
  transform-origin: bottom;
  transition: ${props => props.theme.transition.default("transform")},
    ${props => props.theme.transition.default("opacity")};
  opacity: ${props => props.theme.opacity.faded};
`;

export default Skill;
