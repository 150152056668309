import React from "react";
import styled from "@emotion/styled";
import Section from "../_common/Section";
import Skill from "./components/Skill";
import Container from "../_common/Container";
import { useStaticQuery, graphql } from "gatsby";

const Knowhow = () => {
  const { dataYaml } = useStaticQuery(knowhowQuery);

  return (
    <Section title="Savoir-faire" id="knowhow">
      <Container>
        {dataYaml.knowhow.map((category, categoryIndex) => (
          <Category key={categoryIndex}>
            <CategoryNameBox>
              <CategoryName>{category.type}</CategoryName>
            </CategoryNameBox>
            <CategorySkills>
              {category.skills.map((skill, skillIndex) => (
                <SkillBox key={skillIndex}>
                  <Skill name={skill.name} level={skill.level} />
                </SkillBox>
              ))}
            </CategorySkills>
          </Category>
        ))}
      </Container>
    </Section>
  );
};

const knowhowQuery = graphql`
  query knowhowQuery {
    dataYaml {
      knowhow {
        type
        skills {
          level
          name
        }
      }
    }
  }
`;

const Category = styled.section`
  display: flex;
  padding-bottom: 5rem;
`;

const CategoryNameBox = styled.div`
  width: 3rem;
  position: relative;
  font-size: ${props => props.theme.typography.sizes.bigger}rem;
  opacity: ${props => props.theme.opacity.faded};
`;

const CategoryName = styled.h3`
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: top right;
  transform: translate(-100%, -0%) rotate(-90deg);
  padding-right: 0.5rem;
  margin: 0;
  white-space: nowrap;
`;

const CategorySkills = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
`;

const SkillBox = styled.div`
  margin-bottom: 1rem;
  margin-left: 2%;
  width: 23%;
  ${props => props.theme.breakpoints.down("lg")} {
    width: 31.3%;
  }
  ${props => props.theme.breakpoints.down("md")} {
    width: 48%;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    width: 98%;
  }
  flex-shrink: 1;
`;

export default Knowhow;
