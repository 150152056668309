import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Container from "./Container";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Section = ({ title, id, children }) => {
  useEffect(() => {
    ScrollTrigger.create({
      trigger: `[data-section="${title}"]`,
      pin: `[data-title="${title}"]`,
      pinSpacing: false,
      start: "top top",
      end: `bottom top`,
    });
  }, [title]);

  return (
    <SectionBox id={id} data-section={title}>
      <TitleBox data-title={title}>
        <Container>
          <H2>{title}</H2>
        </Container>
      </TitleBox>
      {children}
    </SectionBox>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  children: PropTypes.node,
};

const SectionBox = styled.section`
  padding-top: ${props => props.theme.spacing.header}rem;
  margin-bottom: 5rem;
`;

const TitleBox = styled.div`
  background-color: ${props => props.theme.palette.background};
  padding-top: 1.1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  z-index: 800;
  position: relative;
`;

const H2 = styled.h2`
  display: flex;
  margin-bottom: 0;

  &::before {
    content: "";
    background-image: url(/images/target.svg);
    background-size: contain;
    display: inline-block;
    height: ${props => props.theme.decoration.targetSize}rem;
    width: ${props => props.theme.decoration.targetSize}rem;
    margin-right: 1rem;
    transform: translateY(0.2rem);
  }
`;

export default Section;
