import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const ScrollingHeader = ({ titles = [], timeout = 2000 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const newIndex = index + 1 < titles.length ? index + 1 : 0;
      setIndex(newIndex);
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [index, timeout, titles]);

  if (titles.length === 0) {
    return <></>;
  }

  return (
    <Wrapper className="js-only">
      <List>
        {titles.map((title, item) => (
          <li key={item} className={item !== index ? "sr-only" : ""}>
            {title}
          </li>
        ))}
      </List>
      <Separator />
    </Wrapper>
  );
};

ScrollingHeader.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.title};
  font-size: ${({ theme }) => theme.typography.sizes.important}em;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  & li {
    display: inline;
  }
`;

const Separator = styled.div`
  margin-top: 0.5em;
  width: 60px;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export default ScrollingHeader;
