import React, { useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import Container from "../_common/Container";
import Section from "../_common/Section";
import Input from "./components/Input";
import { useStaticQuery, graphql } from "gatsby";
import { VscError } from "react-icons/vsc";
import { ImSpinner2 } from "react-icons/im";
import sendNetlifyForm from "../../utils/sendNetlifyForm";

const Contact = () => {
  const {
    dataYaml: { contact },
  } = useStaticQuery(contactQuery);

  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "Bonjour Camille,\n\n",
  });

  const [error, setError] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [disabled, setDisabled] = useState(false);

  const setFormValue = (field, value) => {
    if (form && form.hasOwnProperty(field))
      setForm({ ...form, [field]: value });
  };

  const submitHandler = async event => {
    event.preventDefault();
    let message = "";

    if (!form.name && !form.email) {
      message =
        "J'ai besoin de connaître votre nom et votre email pour pouvoir vous répondre";
    } else if (!form.name) {
      message = "Quel est votre nom ?";
    } else if (!form.email) {
      message = "À quelle adresse email puis-je vous répondre ?";
    }

    if (message) {
      setFeedback(message);
      setError(true);
      return;
    }

    setDisabled(true);
    setError(false);
    setFeedback("Envoi du message");
    try {
      const response = await sendNetlifyForm("contact", form);
      if (response.status !== 200) {
        throw Error();
      } else {
        setFeedback("Merci de votre message !");
      }
    } catch (e) {
      setFeedback(
        "Oups, une erreur technique s'est produite... Je vous invite à me contacter directement par mail."
      );
    }
  };

  return (
    <Section title="Contact" id="contact">
      <Container>
        <Panels>
          <FormPanel>
            {contact.welcome && (
              <WelcomeMessage>{contact.welcome}</WelcomeMessage>
            )}
            <form
              noValidate
              onSubmit={submitHandler}
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <FormLine>
                <Label>À :</Label>
                <span>Camille Guy</span>
              </FormLine>
              <FormLine>
                <Label htmlFor="name" error={error} aria-hidden>
                  De<abbr title="obligatoire">*</abbr> :
                </Label>
                <label htmlFor="name" className="sr-only">
                  Votre nom (obligatoire)
                </label>
                <Input
                  autosize
                  type="text"
                  placeholder="Votre nom"
                  id="name"
                  name="name"
                  maxLength={30}
                  disabled={disabled}
                  value={form.name}
                  onChange={event =>
                    setFormValue("name", event.currentTarget.value)
                  }
                />
                <Label inline htmlFor="email" aria-hidden>
                  {"<"}
                </Label>
                <label htmlFor="email" className="sr-only">
                  Votre email (obligatoire)
                </label>
                <Input
                  autosize
                  type="email"
                  placeholder="Votre email"
                  id="email"
                  name="email"
                  disabled={disabled}
                  value={form.email}
                  onChange={event =>
                    setFormValue("email", event.currentTarget.value)
                  }
                />
                <Label inline htmlFor="email" aria-hidden>
                  {">"}
                </Label>
              </FormLine>
              <FormLine>
                <Label htmlFor="subject" aria-hidden>
                  Objet :
                </Label>
                <label htmlFor="subject" className="sr-only">
                  Objet de votre message
                </label>
                <Input
                  type="text"
                  placeholder="Objet de votre message"
                  id="subject"
                  name="subject"
                  maxLength={100}
                  disabled={disabled}
                  value={form.subject}
                  onChange={event =>
                    setFormValue("subject", event.currentTarget.value)
                  }
                />
              </FormLine>
              <Separator />
              <FormLine>
                <label htmlFor="message" className="sr-only">
                  Votre message
                </label>
                <Textarea
                  id="message"
                  name="message"
                  rows={10}
                  cols={50}
                  disabled={disabled}
                  value={form.message}
                  onChange={event =>
                    setFormValue("message", event.currentTarget.value)
                  }
                />
              </FormLine>
              <Separator />
              <SubmitContainer>
                <button disabled={disabled}>Envoyer</button>
                <Feedback id="feedback" aria-live="assertive">
                  {error && <ErrorPicto title="erreur" />}
                  {feedback === "Envoi du message" && <Spinner aria-hidden />}
                  {feedback}
                </Feedback>{" "}
              </SubmitContainer>
            </form>
          </FormPanel>
          <ImagePanel>
            <Image />
          </ImagePanel>
        </Panels>
      </Container>
    </Section>
  );
};

const contactQuery = graphql`
  query ContactQuery {
    dataYaml {
      contact {
        welcome
      }
    }
  }
`;

const Panels = styled.div`
  display: flex;
`;

const FormPanel = styled.div`
  width: 60%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const ImagePanel = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;

const Image = styled.div`
  background-image: url(/images/paperplane.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  display: inline-block;
  height: 100%;
  width: 50%;
`;

const WelcomeMessage = styled.div`
  margin-bottom: 3rem;
`;

const FormLine = styled.div`
  padding: 0.4rem 0;
  white-space: nowrap;
`;

const Label = styled.span`
  display: inline-block;
  min-width: ${({ inline }) => (inline ? `0` : `5rem`)};
  margin: ${({ inline }) => (inline ? `0 0.25rem` : `0`)};
  ${({ error, theme }) => error && `color: ${theme.palette.error};`};

  abbr {
    text-decoration: none;
  }
`;

const Separator = styled.div`
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.palette.white};
  height: 1px;
  opacity: ${({ theme }) => theme.opacity.light};
`;

const Textarea = styled.textarea`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.white};
  width: 100%;
  resize: none;
  outline: none;
`;

const SubmitContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
`;

const Feedback = styled.small`
  margin: 1rem 0 0 2rem;
`;

const ErrorPicto = styled(VscError)`
  color: ${({ theme }) => theme.palette.error};
  margin-right: 0.5rem;
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(ImSpinner2)`
  color: ${({ theme }) => theme.palette.accent};
  animation: ${spinAnimation} 1s linear infinite;
  margin-right: 0.5rem;
`;

export default Contact;
