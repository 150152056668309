import React from "react";
import styled from "@emotion/styled";
import Section from "../_common/Section";
import ProjectCard from "./components/ProjectCard";
import Container from "../_common/Container";
import { useStaticQuery, graphql } from "gatsby";

const Achievements = () => {
  const { dataYaml } = useStaticQuery(achievementsQuery);

  return (
    <Section title="Réalisations" id="achievements">
      <Container>
        <ProjectGrid>
          {dataYaml.projects.map((project, index) => (
            <ProjectBox key={index}>
              <ProjectCard {...project} />
            </ProjectBox>
          ))}
        </ProjectGrid>
        <SeeMore>Plus de projets, concepts et expérimentations sur mes comptes <a href="//github.com/caguy" rel="noreferrer" target="_blank">Github</a> et <a href="//codepen.io/camilleguy/" rel="noreferrer" target="_blank">Codepen</a></SeeMore>
      </Container>
    </Section>
  );
};

const achievementsQuery = graphql`
  query achievementsQuery {
    dataYaml {
      projects {
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        name
        summary
        slug
      }
    }
  }
`;

const ProjectGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProjectBox = styled.article`
  width: 33%;
  ${props => props.theme.breakpoints.down("md")} {
    width: 49%;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    width: 100%;
  }
  margin: 1px;
`;

const SeeMore = styled.p`
  text-align: center;
  margin-top: 1rem;
`;

export default Achievements;
