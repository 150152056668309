import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Input = ({ id, autosize = false, ...others }) => {
  return (
    <>
      {autosize ? (
        <InputContainer>
          <AutosizedInputField id={id} {...others} />
          <Placeholder>{others.value || others.placeholder}</Placeholder>
        </InputContainer>
      ) : (
        <InputField id={id} {...others} />
      )}
    </>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  autosize: PropTypes.bool,
  others: PropTypes.object,
};

const InputContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const InputField = styled.input`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.white};
  padding: 0;
  outline: none;
  width: 75%;

  &::placeholder {
    color: ${({ theme }) => theme.palette.white};
    opacity: ${({ theme }) => theme.opacity.light};
  }

  /* Removing auto-fill styling */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out !important;
    -webkit-transition-delay: 9999s !important;
  }
`;

const AutosizedInputField = styled(Input)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
`;

const Placeholder = styled.span`
  white-space: pre;
  visibility: hidden;
`;

export default Input;
