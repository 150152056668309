import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link as GatsbyLink } from "gatsby";
import { gsap } from "gsap/gsap-core";
import BackgroundImage from "gatsby-background-image";

const ProjectCard = ({ name, summary, image, slug }) => {
  const [isHovered, setIsHovered] = useState(false);
  const textRef = useRef(null);
  const overlayRef = useRef(null);
  const animation = useRef(null);

  useEffect(() => {
    animation.current = gsap
      .timeline({ paused: true })
      .from(
        textRef.current.children,
        {
          opacity: 0,
          y: "+=200",
          stagger: 0.12,
          ease: "power3.out",
        },
        0
      )
      .to(
        overlayRef.current,
        {
          opacity: 0,
          ease: "power1.inOut",
          duration: 0.25,
        },
        0
      );
  }, [textRef, overlayRef]);

  useLayoutEffect(() => {
    if (!animation.current) return;
    if (isHovered) {
      animation.current.play();
    } else {
      animation.current.reverse();
    }
  }, [isHovered]);

  // Image has to be memoized to avoid a reload on hover
  const imageComponent = useMemo(
    () => <Image fluid={image.childImageSharp.fluid} />,
    [image]
  );

  return (
    <Link
      to={`/project/${slug}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      className="no-style"
    >
      <ProjectCardBox>
        <InnerBox>
          {imageComponent}
          <Text ref={elt => (textRef.current = elt)}>
            <Name>{name}</Name>
            {summary && <Summary>{summary}</Summary>}
          </Text>
          <Overlay
            ref={elt => (overlayRef.current = elt)}
            isHovered={isHovered}
          />
        </InnerBox>
      </ProjectCardBox>
    </Link>
  );
};

ProjectCard.propTypes = {
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  image: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};

const Link = styled(GatsbyLink)`
  color: inherit;
  text-decoration: none;
`;

const ProjectCardBox = styled.div`
  width: 100%;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
`;

const InnerBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Image = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`;

const Text = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  width: calc(100% - 4rem);
  z-index: 10;
  text-shadow: 0px 0px 7px ${props => props.theme.palette.background};
`;

const Name = styled.h3`
  font-family: ${props => props.theme.typography.title};
  font-size: ${props => props.theme.typography.sizes.bigger}rem;
  margin: 0;
`;

const Summary = styled.div`
  padding: 1rem 0;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.palette.background};
  opacity: 0.75;
  mix-blend-mode: color;
`;

export default ProjectCard;
