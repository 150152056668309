import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useEffect } from "react";
gsap.registerPlugin(ScrollToPlugin);

const useSmoothScroll = () => {
  useEffect(() => {
    const scrollTo = (event, id) => {
      event.preventDefault();
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      const y =
        document.getElementById(id).getBoundingClientRect().top + scrollY;
      gsap.to(window, { duration: 2, ease: "power4.inOut", scrollTo: y });
    };

    setTimeout(() => {
      // Waiting for the DOM to be available by pushing the init into the JS Event Queue, ie after react rendered
      [...document.querySelectorAll("a[href^='#'")].forEach(element => {
        element.addEventListener("click", event => {
          scrollTo(
            event,
            element.href.substring(element.href.indexOf("#") + 1)
          );
        });
      });
    }, 0);
  }, []);
};

export default useSmoothScroll;
