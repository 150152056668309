import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Markdown from "react-markdown";

const Offer = ({ title, description }) => {
  return (
    <OfferBox>
      <VerticalLine />
      <H3>{title}</H3>
      <Markdown source={description} />
      <a href="#contact">
        <button tabIndex={-1}>Me contacter</button>
      </a>
    </OfferBox>
  );
};

Offer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const OfferBox = styled.article`
  padding-left: calc(
    ${props => props.theme.decoration.smallTargetSize / 2}px + 2rem
  );
  position: relative;

  & p {
    margin: 0;

    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }
`;

const VerticalLine = styled.div`
  background-color: ${props => props.theme.palette.accent};
  position: absolute;
  top: ${props => props.theme.decoration.smallTargetSize}px;
  left: ${props => props.theme.decoration.smallTargetSize / 2}px;
  height: calc(100% - ${props => props.theme.decoration.smallTargetSize}px);
  width: 1px;

  &::before {
    content: "";
    background-image: url(/images/target_accent.svg);
    height: ${props => props.theme.decoration.smallTargetSize}px;
    width: ${props => props.theme.decoration.smallTargetSize}px;
    position: absolute;
    top: -${props => props.theme.decoration.smallTargetSize}px;
    left: -${props => props.theme.decoration.smallTargetSize / 2}px;
  }
`;

const H3 = styled.h3`
  padding-top: 0.15rem;
  color: ${props => props.theme.palette.accent};
`;

export default Offer;
