import axios from "axios";

const sendNetlifyForm = async (formName, formData) => {
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  return axios.post("/", encode({ "form-name": formName, ...formData }), {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export default sendNetlifyForm;
