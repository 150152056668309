import React from "react";
import styled from "@emotion/styled";
import Offer from "./components/Offer";
import ScrollingHeader from "./components/ScrollingHeader";
import Container from "../_common/Container";
import Markdown from "react-markdown";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import DigitalExperienceSvg from "../../../static/images/digitalExperience.svg";
import Speech from "../../../static/images/speech.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const {
    dataYaml: { about },
  } = useStaticQuery(aboutQuery);

  return (
    <section id="about">
      <Container>
        <Intro>
          <Header data-reveal>
            <Picture fluid={about.image.childImageSharp.fluid} />
            <H1>
              <Title>{about.title}</Title>
              <Subtitle>{about.subtitle}</Subtitle>
            </H1>
          </Header>
          <h2 className="sr-only">À propos</h2>
          <WhoAmI data-reveal-on-scroll>
            <SpeechAdornment src={Speech} alt="" />
            <Markdown source={about.whoami} />
          </WhoAmI>
        </Intro>
        <Hook data-reveal-on-scroll>
          <ScrollingHeader titles={about.services} timeout={900} />
          <Punchline>
            <Markdown source={about.punchline} />
          </Punchline>
          <Illustration src={DigitalExperienceSvg} alt={about.punchline} />
        </Hook>
        <OfferGrid>
          {about.offers.map((offer, index) => (
            <OfferBox key={index}>
              <Offer title={offer.title} description={offer.description} />
            </OfferBox>
          ))}
        </OfferGrid>
      </Container>
    </section>
  );
};

const aboutQuery = graphql`
  query AboutQuery {
    dataYaml {
      about {
        title
        subtitle
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        whoami
        services
        punchline
        offers {
          description
          title
        }
      }
    }
  }
`;

const mdImageRatio = 0.75;
const xsImageRatio = 0.4;

const Intro = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25vh;
`;

const Picture = styled(BackgroundImage)`
  background-color: ${props => props.theme.palette.accent};
  border-radius: 50%;
  overflow: hidden;
  width: ${props => props.theme.decoration.circleSize}px;
  height: ${props => props.theme.decoration.circleSize}px;
  background-size: contain;
  margin-right: 2rem;
  ${props => props.theme.breakpoints.down("md")} {
    width: ${props => props.theme.decoration.circleSize * mdImageRatio}px;
    height: ${props => props.theme.decoration.circleSize * mdImageRatio}px;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    width: ${props => props.theme.decoration.circleSize * xsImageRatio}px;
    height: ${props => props.theme.decoration.circleSize * xsImageRatio}px;
    margin-right: 1rem;
  }
`;

const H1 = styled.h1`
  margin: 0;
`;

const Title = styled.p`
  font-size: ${props => props.theme.typography.sizes.title}rem;
  margin: 0;
  ${props => props.theme.breakpoints.down("md")} {
    font-size: ${props => props.theme.typography.sizes.title * 0.75}rem;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    font-size: ${props => props.theme.typography.sizes.title * 0.5}rem;
  }
`;

const Subtitle = styled.p`
  font-size: ${props => props.theme.typography.sizes.title * 0.5}rem;
  color: ${props => props.theme.palette.accent};
  margin: 1.5rem 0 0 0;
  ${props => props.theme.breakpoints.down("md")} {
    font-size: ${props => props.theme.typography.sizes.title * 0.375}rem;
    margin-top: 0.5rem;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    font-size: ${props => props.theme.typography.sizes.title * 0.25}rem;
    margin-top: 0rem;
  }
`;

const WhoAmI = styled.div`
  box-sizing: border-box;
  position: relative;
  margin: 3rem 0 0 0;
  padding: 1rem 0;
  border-top: solid 1px ${({ theme }) => theme.palette.accent};
  font-family: ${props => props.theme.typography.title};
  font-size: ${props => props.theme.typography.sizes.important}rem;
  max-width: 580px;
  width: 66%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 90%;
    max-width: 250px;
    font-size: ${props => props.theme.typography.sizes.regular}rem;
  }

  & > p {
    margin: 0;
    line-height: ${props => props.theme.typography.lineHeights.bigger};

    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }
`;

const SpeechAdornment = styled.img`
  position: absolute;
  top: 2px;
  left: 20%;
  transform: translateY(-100%);
`;

const Hook = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10rem;
  padding-bottom: 5rem;
`;

const Punchline = styled.div`
  margin-top: -0.5rem;
  font-size: ${props => props.theme.typography.sizes.biggest}em;
  font-family: ${props => props.theme.typography.title};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: ${({ theme }) => theme.typography.sizes.bigger}rem;
  }

  & > p {
    color: ${props => props.theme.palette.accent};
    text-align: center;
    line-height: ${props => props.theme.typography.lineHeights.bigger};
    & em {
      color: ${props => props.theme.palette.white};
    }
  }
`;

const OfferGrid = styled.div`
  padding-bottom: 6rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OfferBox = styled.div`
  width: 42%;
  ${props => props.theme.breakpoints.down("md")} {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }
  flex-shrink: 1;
`;

const Illustration = styled.img`
  width: 100%;
  max-width: 520px;
`;

export default About;
