import React, { useEffect } from "react";
import Layout from "../components/_common/layout";
import About from "../components/About/About";
import Achievements from "../components/Achievements/Achievements";
import Knowhow from "../components/Knowhow/Knowhow";
import Contact from "../components/Contact/Contact";
import useSmoothScroll from "../utils/useSmoothScroll";
import { gsap } from "gsap";

const IndexPage = () => {
  useSmoothScroll();

  useEffect(() => {
    const htmlElt = document.querySelector("html");
    if (!htmlElt.hasAttribute("data-homepage"))
      htmlElt.setAttribute("data-homepage", true);

    if (window.scrollY === 0) {
      gsap.to("[data-reveal]", {
        opacity: 1,
        duration: 1.5,
        delay: 0,
        ease: "power2.in",
      });

      gsap.set("[data-reveal-on-scroll]", {
        y: 30,
      });

      gsap.to("[data-reveal-on-scroll]", {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: "power.inOut",
        scrollTrigger: "document",
      });

      gsap.to("[data-reveal-with-delay]", {
        opacity: 1,
        duration: 2,
        delay: 1,
        ease: "power2.in",
      });
    } else {
      gsap.set(
        "[data-reveal], [data-reveal-with-delay], [data-reveal-on-scroll]",
        {
          opacity: 1,
        }
      );
    }

    return () => {
      htmlElt.removeAttribute("data-homepage");
    };
  });

  return (
    <Layout>
      <About />
      <Achievements />
      <Knowhow />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
